import React, { useState } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


const colorCode={
                  "":"#bdbdbd",
                  "1":"#00833e",
                  "2":"#a2d45e",
                  "3":"#ffe76a",
                  "4":"#ff8300",
                  "5":"#e84545"
                }



//filtering function
function  filterByValue (array, string) {
  return array.filter(o =>
      Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
}


export default function RiskChartFusion ({props,quarter}) {
  // Sort by quarter 
  let quarters = [];
  for (let i = 0; i < props.length; i++){
      quarters[i] = props[i]["Quarter"];
  }
  let dataSource={};
  let localquarter, previousquarter, previous, selected;
  let prevPolitical, prevRechnical, prevPrivateSector, prevGovernement, prevBudget,prevTimeline;
  let selPolitical, selRechnical, selPrivateSector, selGovernement, selBudget, selTimeline ;
  // If Quarter does exist in the list of quarters
 if (quarters.includes(quarter)){
    localquarter=quarter;
    previousquarter=  quarters.sort().indexOf(quarter) !== 0 ? quarters[quarters.sort().indexOf(quarter)-1] : quarters[quarters.sort().indexOf(quarter)] ;
    previous = filterByValue(props, previousquarter);
    selected = filterByValue(props, localquarter);
    prevPolitical = previous[0]['Country political'] ;
    prevRechnical = previous[0]['Project technical'];
    prevPrivateSector = previous[0]['Private sector support'];
    prevGovernement = previous[0]['Government commitment'];
    prevBudget = previous[0]['Project budget'];
    prevTimeline = previous[0]['Project timelines'];
    selPolitical = selected[0]['Country political'];
    selRechnical = selected[0]['Project technical'];
    selPrivateSector = selected[0]['Private sector support'];
    selGovernement = selected[0]['Government commitment'];
    selBudget = selected[0]['Project budget'];
    selTimeline = selected[0]['Project timelines'];

  }else{
    localquarter=previousquarter='-';
    prevPolitical = '';
    prevRechnical = '';
    prevPrivateSector =  '';
    prevGovernement = '';
    prevBudget = '';
    prevTimeline = '';
    selPolitical = '';
    selRechnical = '';
    selPrivateSector = '';
    selGovernement =  '';
    selBudget = '';
    selTimeline = '';
  }

  // risk previous:
 


  let heatmapConstantOptions = {
    chart: {
      type: "heatmap",
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1,
      width: 250
    },
    title: {
      text: ""
    },
    yAxis: {
      categories: [
        "Country political", 
        "Project technical", 
        "Private sector support", 
        "Government commitment", 
        "Project budget",
        "Project timelines",
      ],
      title: null,
      reversed: true,
      width: 20
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }

 try {
   dataSource = {
    xAxis: {
      categories: [
        previousquarter,
        localquarter,
      ]
    },
    series: [
      {
        borderWidth: .5,
        dataLabels: {
          enabled: true,
          color: "#000000"
        },
        data: [
                { x: 0,
                  y: 0,
                  value: prevPolitical,
                  name: "Country political",
                  color: colorCode[prevPolitical] 
                },{ x: 1,
                  y: 0,
                  value: selPolitical,
                  name: "Country political",
                  color: colorCode[selPolitical] 
                },
                { x: 0,
                  y: 1,
                  value: prevRechnical,
                  name: "Project technical",
                  color: colorCode[prevRechnical] 
                },
                { x: 1,
                  y: 1,
                  value: selRechnical,
                  name: "Project technical",
                  color: colorCode[selRechnical] 
                },
                { x: 0,
                  y: 2,
                  value: prevPrivateSector,
                  name: "Private sector support",
                  color: colorCode[prevPrivateSector] 
                },{ x: 1,
                  y: 2,
                  value: selPrivateSector,
                  name: "Private sector support",
                  color: colorCode[selPrivateSector] 
                },{ x: 0,
                  y: 3,
                  value: prevGovernement,
                  name: "Government commitment",
                  color: colorCode[prevGovernement] 
                },{ x: 1,
                  y: 3,
                  value: selGovernement,
                  name: "Government commitment",
                  color: colorCode[selGovernement] 
                },{ x: 0,
                  y: 4,
                  value: prevBudget,
                  name: "Project budget",
                  color: colorCode[prevBudget] 
                },{ x: 1,
                  y: 4,
                  value: selBudget,
                  name: "Project budget",
                  color: colorCode[selBudget] 
                },{ x: 0,
                  y: 5,
                  value: prevTimeline,
                  name: "Project timelines",
                  color: colorCode[prevTimeline] 
                },{ x: 1,
                  y: 5,
                  value: selTimeline,
                  name: "Project timelines",
                  color: colorCode[selTimeline] 
                },
              ],
        
      }
    ]

}
  } catch (error) {
    dataSource={}
  } 
  


// Adding Heat map options to the constant options
dataSource={
  ...heatmapConstantOptions,
  ...dataSource
}
return (
  <HighchartsReact highcharts={Highcharts} options={dataSource} />
);
    
}
