import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar,Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import firebase from './firebase';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold", // or 'bold'
    color: "#174B88" // or 'bold'
  },
  logo: {
    maxWidth: 150,
    margin: '10px',
    align: 'left',
  }
}));

export default function Header(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit"  >
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            {/* <MenuIcon /> */}
          </IconButton>
          <img src="http://pub-engagement.s3.amazonaws.com/gatf/GATF_logo.png" alt="GATF" className={classes.logo} />
          <Typography variant="h4" align="center"  color="inherit" className={classes.title} >
          Projects Tracking Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
  
}
