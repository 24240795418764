import React , {useState } from 'react';
import ProjectInformation from './ProjectInformation/ProjectInformation'
import Timelines from './Timeline/Timelines';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RiskChartFusion from './Risk/RiskChartFusion'
import GroupedPieCharts from '../Finance/Items/GroupedPieCharts';
import useFilteredData from '../../../../filter/useFilteredData';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Outlook from './Outlook';

import { styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const RiskChartLegend = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
  fontSize: '.75em',
  paddingTop: '4em'
}));

  
// DataFilterContext

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },chart: {
    padding: theme.spacing(2),
    textAlign: 'center',
    
  },
  circular: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    listItemText:{
      fontSize:'.25em',
    }
  }
}));
// Functions
function  filterByValue (array, string) {
  return array.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(string.toLowerCase())));
}


export default function OverviewTable(props) {
  const classes = useStyles();
  const {
    filteredData,
    projectSelect,
    handleFilterSelected
  } = useFilteredData(props);
  //**************Filtering 
  // Get selected Project: currentProject
  let currentProject = filterByValue (filteredData.data, filteredData.filterObj.Project)
  // Get quarters in selected project
  const quarterSet = new Set();
  let quarterArray=[]
  currentProject.forEach(function(value, index, array) {
    for (const item of value.Finance) {
      quarterSet.add(item.Quarter);
    }
    // for (const item of value) {
    //   quarterSet.add(item.Quarter);
    // }
    quarterSet.add(value.Quarter);
    for (const item of value.Risks) {
      quarterSet.add(item.Quarter);
    }
    for (const item of value.Milestones) {
      quarterSet.add(item.Quarter);
    }
    for (const item of value.NextSteps) {
      quarterSet.add(item.Quarter);
    }
  }); 
  let currentQuarters = [...quarterSet]; 
  if(filteredData.filterObj.Quarter){
    quarterArray=[filteredData.filterObj.Quarter]
  }else{
    quarterArray=currentQuarters
  }

  let portfolioTotal, inkindEstimation;
    // Calculate Portfolio Total sum and  inkindEstimation
    if (props){
      portfolioTotal = props.seriesData.reduce(function (s, a) {
          return s + a["Project Budget"];
      }, 0);
      inkindEstimation= filteredData.data[0]['In-kind Estimation'];
      
    }
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* Filter */}
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="project-name">Project Name</InputLabel>
              <Select
                native
                value={props.seriesData.Project ? props.seriesData.Project :currentProject[0].Project}
                onChange={handleFilterSelected}
                inputProps={{
                  name: 'Project',
                  id: 'project-name',
                }}
              >
                <option aria-label="None" value="" />
                {projectSelect.map((project) => 
                  (<option value={project}>{project}</option>)
                )} 
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="quarter">Quarter</InputLabel>
              <NativeSelect
                value={props.seriesData.Quarter}
                onChange={handleFilterSelected}
                inputProps={{
                  name: 'Quarter',
                  id: 'quarter',
                }}>
                <option aria-label="None" value="" />
                {currentQuarters.sort().reverse().map((select) => 
                  (<option value={select}>{select}</option>)
                )} 
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
    
      {props.seriesData ? <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProjectInformation props={currentProject} quarters={quarterArray}/> 
          </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12}>  
            <Typography  variant="h6">Outlook</Typography>        
              <Paper className={classes.paper}>
                <Outlook key={12}  props={currentProject} quarters={quarterArray} />
              </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={1} style={{backgroundColor: 'white'}}>
          <Grid item xs={8}>
            <Typography  variant="h6">Finance</Typography>
              <Grid container spacing={3}>
                <GroupedPieCharts portfolioTotal={portfolioTotal} props={currentProject} totalbudget={filteredData.data[0]["Project Budget"]} quarters={currentQuarters} quarter={filteredData.filterObj.Quarter} inkindEstimation={inkindEstimation}/> 
              </Grid>
          </Grid>
        <Grid item xs={4}>
        <Typography  variant="h6">Contextual Risk</Typography>
            <Grid container spacing={0}>
                <Grid item  xs={7} >
                  <RiskChartFusion props={currentProject[0].Risks} quarter={filteredData.filterObj.Quarter}/> 
                </Grid>
                <Grid item  xs={5}>
                <RiskChartLegend>
                  <List >
                      <ListItem>
                        <ListItemIcon>
                          <FiberManualRecordIcon className={{primary:classes.listItemText}} style={{ color: '#00833e', fontSize: '1.25em'}} /> Nil
                        </ListItemIcon>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        <FiberManualRecordIcon style={{ color: '#a2d45e', fontSize: '1.25em' }} /> Low
                        </ListItemIcon>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <FiberManualRecordIcon style={{ color: '#ffe76a', fontSize: '1.25em' }} /> Medium
                        </ListItemIcon>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <FiberManualRecordIcon style={{ color: '#ff8300', fontSize: '1.25em' }} /> High
                        </ListItemIcon>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <FiberManualRecordIcon style={{ color: '#e84545', fontSize: '1.25em' }} /> Critical
                        </ListItemIcon>
                      </ListItem>
                  </List>
                </RiskChartLegend>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
        
        <br/>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* <Typography  variant="h6">TIMELINE</Typography> */}
              <Timelines props={filteredData} allquarters={currentQuarters} selectedquarter={filteredData.filterObj.Quarter}/> 
          </Grid>
         
        </Grid>  </> : <div className={classes.circular}><CircularProgress /></div>}
       
      </div>
    );
}