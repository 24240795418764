import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InformationTab from './Items/InformationTab';
import DetailsTab from './Items/DetailsTab';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';



const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // height:300,
    overflow:'scroll',
    display:'block',
    zIndex: 5,
    backgroundColor: theme.palette.background.paper,
  },
  details: {
      display: 'flex'
  }
}));

export default function ProjectInformation({props,quarters}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel2');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

 //**************Filtering 
  // Get selected Project: currentProject
  let currentProject = props[0]
  // Get quarters in selected project ['Details']

  return (
    <div className={classes.root}>
      
      <Accordion square expanded= 'true' /*{expanded === 'panel1'}*/  onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Project Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props ? <InformationTab props={props}  /> : ''}
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded='true' /*{expanded === 'panel2'}*/ onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Project Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props ? <DetailsTab props={currentProject} quarter={quarters.sort().at(-1)}/> : ''}
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
